import * as React from "react";
import { Link } from "gatsby";

import { Box, Flex, Text } from "@chakra-ui/react";

export function Footer() {
  return (
    <Box as="footer" mt="100px" mb="20px">
      <Flex
        as="nav"
        aria-label="Site navigation"
        justify="space-between"
        alignItems="center"
      >
        <Text>©PARSK</Text>
        <Flex alignItems="center">
          <Link to="/about">
            <Text
              textTransform="uppercase"
              mr="20px"
              _hover={{ color: "gray.500" }}
            >
              À propos
            </Text>
          </Link>
          <Link to="/contact">
            <Text textTransform="uppercase" _hover={{ color: "gray.500" }}>
              Contact
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
