import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Box, Button, Flex, IconButton, useColorMode } from "@chakra-ui/react";
import { SunIcon, MoonIcon, ChatIcon } from "@chakra-ui/icons";
import LogoDark from "../images/logoBlack.svg";
import LogoWhite from "../images/logoWhite.svg";
// import LogoWhite from "../images/logo_Parsk_white.png";

export function Header() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box as="header">
      <Flex
        as="nav"
        aria-label="Site navigation"
        justify="space-between"
        alignItems="center"
      >
        <Link to="/">
          {colorMode === "light" ? (
            <img
              // src="../images/logoBlack.svg"
              src={LogoDark}
              alt="logo PARSK black"
              width="40px"
            />
          ) : (
            // <StaticImage
            //   src="../images/logo_Parsk.png"
            //   alt="Logo PARSK"
            //   height={40}
            // />
            <img src={LogoWhite} alt="logo PARSK white" width="40px" />
            // <StaticImage
            //   src="../images/logo_Parsk_white.png"
            //   alt="Logo PARSK"
            //   height={40}
            // />
          )}
        </Link>
        <Flex alignItems="center">
          <IconButton
            onClick={toggleColorMode}
            maxW="30px"
            variant="outline"
            colorScheme="black"
            _hover={{ color: "gray" }}
            fontSize="xl"
            mr="10px"
          >
            {colorMode === "dark" ? <SunIcon /> : <MoonIcon />}
          </IconButton>
          <Link to="/contact">
            <IconButton
              // onClick={toggleColorMode}
              maxW="30px"
              variant="outline"
              colorScheme="black"
              _hover={{ color: "gray" }}
              fontSize="xl"
            >
              <ChatIcon />
            </IconButton>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
